export const footerContent={
  registrationlabel: 'Pellisambandlu © 2023 - All rights reserved | Designed by Accumenta services Pvt Ltd',
  infoText: 'Selecting a life partner is like adding a pecious melody to the song of your life, forever altering its tune. the sweetest symphony is found in choosing the perfect harmony. How delightful it would be to have a trusted companion to join in this heartarming duet of choice.',
  Mobile: 'Mobile: +91 8247412579',
  Email: 'Email: CustomerCare @Pellisambandalu.com',
  contactLabel: 'Contact Us',
  privacyLabel: 'Privacy & You',
  pp: 'Privacy policy',
  TermsC: 'Terms & Condition',
  copyright: 'Copyright@2024 All rights reserved'
}
export const addPreference = {
  hedding: "Add Preferences",
  ProfileCreatedFor: "Profile is required.",

  MinAge: "Min Age is required.",

  MaxAge: "Max Age is required.",

  MinHeight: "Min Height is required.",

  MaxHeight: "Max height is required.",

  MotherTongue: "Mother tongue is required.",

  Religion: "Religion is required.",

  Caste: "Caste is required.",

  Star: "Star is required.",

  Dosham: "Dhosam is required.",

  Education: "Education is required.",

  Occupation: "Occupation is required.",

  Employment: "Employment is required.",

  AnnualIncome: "Annual Income is required.",

  City: "City is required.",

  State: "State is required.",

  Country: "Country is required.",

  MaritalStatus: "Marital Status is required.",

  Disability: "Disability is required.",

  DrinkingHabits: "Drinking Habits is required.",

  SmokingHabits: "Smoking Habits is required.",

  EatingHabits: "Eating Habits is required.",

  profile: "Profile Created For",

  minage: "MinAge",

  maxage: "MaxAge",

  minheight: "MinHeight",

  maxheight: "MaxHeight",

  mothertongue: "Mother Tongue",

  religion: "Religion",

  caste: "Caste",

  star: "Star",

  dosham: "Dosham",

  education: "Education",

  occupation: "Occupation",

  employmentstatus: "Employment Status",

  annualincome: "Annual Income",

  city: "City",

  state: "State",

  country: "Country",

  maritalstatus: "Marital Status",

  disability: "Disability",

  drinkinghabits: "Drinking Habits",

  smokinghabits: "Smoking Habits",

  eatinghabits: "Eating Habits",

  submit: "Submit",
  select:"Select",

};
export const headerContent={
  logo: 'Pellisambandlu',
  home:'Home',
  about:'About Us',
  brides:'Brides',
  firstb:'First Marraige Brides',
  secondb:'Second Marraige Brides',
  grooms:'Grooms',
  firstg:'First Marraige Grooms',
  secondg:'Second Marriage Grooms',
  contact:'Contact',
}
export const toastsuccess={
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  theme: 'colored',
  type: 'success',
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
}
export const toastError={
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  theme: 'colored',
  type: 'error',
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
}
export const login={
  login: 'Login',
  emailPlaceholder:'Email',
  passwordPlaceholder:'Password',
  forgot:'Forgot password ?',
  noAccount:'Don\'t have an account? ',
  signup:'Signup',
  otpPlaceHolder:'Please Enter OTP',
  mobilePlaceholder:"Mobile Number"
}
export const forgot={
  subbtn: 'Submit',
  emailPlaceholder:'Email',
  verify:'Verify',
  passwordPlaceholder:'Password',
  forgot:'Forgot Password',
  forgotMsg:'Enter your email and we\'ll send you a link to reset your password',
  signup:'Signup'
}
export const singnup = {
  title: "Signup",
  gender: "I am",
  otpVerification: "OTP Verification",
  otpSent: "One - Time password sent to your registered mobile number",
  enterOtp: "Enter Otp",
  resendOtp: "Didn’t receive the OTP? ",
  resend: "Resend OTP",
  verify: "Verify",
  verifiedSuccessfully: "Verified Successfully",
  mobileVerified: "Your mobile number has been successfully verified",
  fullName: "Full Name",
  emailId: "Email Id",
  password: "Password",
  reenterPassword: "Re-enter Password",
  enterNumber: "Enter Number",
  agreeTerms: "I agree to  ",
  terms_policy1: "Terms",
  terms_policy2 :"Privacy policy",
  alreadyAccount: "Already have an account? ",
  welcomeBack: "Welcome to",
  description: "Our expert team provides comprehensive planning and personalized services to ensure your special day is perfect. Trust us to turn your dream wedding into reality. Contact us today!",
  login: "Login",
  passwordError:"please enter a password",
  repassErr:"please re-enter your password"
};
 
export const religion=[
  'Hindu',
  'Muslim',
  'Cristian',
  'Sikh',
  'Jain',
  'Buddist',
  'Others'
]

export const settings={
  settingsTitle: 'Settings',
  changeEmailTitle: 'Change your email',
  emailLabel: 'Email',
  emailPlaceholder: 'Enter your email',
  validEmailError: 'Please enter a valid email address',
  otpPlaceholder: 'Enter OTP',
  newMailPlaceholder: 'Enter New Mail',
  verifyButton: 'Verify',
  changePasswordTitle: 'Change Password',
  currentPasswordPlaceholder: 'Enter Current Password',
  newPasswordPlaceholder: 'Enter New Password',
  confirmPasswordPlaceholder: 'Confirm Password',
  passwordFieldsError: 'All password fields are required',
  passwordMatchError: 'New Password and Confirm Password do not match',
  savePasswordButton: 'Save Password',
  profilePrivacyTitle: 'Your Profile Privacy',
  profilePrivacyLabel: 'Let others know that I shortlisted their profile',
  deleteProfileTitle: 'Delete Profile',
  deleteProfileCheckboxLabel: 'Please choose a reason for profile deletion',
  deleteReasonLabel: 'Reason for deletion',
  marriedLabel: 'Married',
  notInterestedLabel: 'Not interested',
  logoutTitle: 'Logout',
  logoutMessage: 'If you have any questions or need further assistance, please feel free to contact our support team',
  logoutButton: 'Logout',
  saveAllButton: 'Save',
  deleteTitle: 'Are you sure?',
  deleteSubTitle: 'This action will permanently delete your profile data',
  cancel: 'Cancel',
  deleteBtn: 'Delete',
  phonePlaceholder: 'Enter your phone number',
  foundAMatch:"Found a Match",
  financeIssue:"Finance issue",
  trustIssue:"Trust issue",
  validPhoneError:"Please enter a valid phone number",
  changePhoneTitle:"Change your mobile number",
  newPhonePlaceholder:"Enter new phone number",
}
export const EditProfile={
  editprofile:"Edit Profile",
  save:"Save",
  basicdetails:"Basic Details",
  personaldetails:"Personal Details",
  edit:"Edit",
  professionaldetails:"Professional Details",
  media:"Media",
  add:"Add",
}
export const ignoreUserText={
  heading:'Ignorelisted',
  Age:'Age',
  Religion:'Religion',
  ViewProfileBtn:'View Profile',
  heading1:'Shortlisted'
}

export const userProfile ={
  Religion: "Religion",
  Family: {
      FamilyInformation: "Family Informaton",
  },
  personal: {
      PersonalInformation: "Personal Information",
  },
  Professional: {
      ProfessionalDetail: "Perofessional Details",
  },

}
export const CustomSideBar = {
  title: "Add / Edit your profile photo",
  fromGallery: "From Gallery",
  fromCamera: "From Camera",
  cancel: "Cancel"
};

export  const registerMain = {
  imgHeading:'Welcome to',
  textIlaic:'pellisambandalu',
  imgtext:'Our expert team provides comprehensive planning and personalized services to ensure your special day is perfect. Trust us to turn your dream wedding into reality. Contact us today!'
}

export const registration1={
  education:"Highest Education ",
  field:"This field is required",
  occupation:"Occupation*",
  employment:"Employment Type ",
  income:"Annual Income ",
  year:"Year of Passing",
  place:"Place of Birth ",
  time:"Time of Birth",
  city:"Citizenship",
  language :"Language Proficiency",
  instagram:"Instagram ID",
  linkedIn:"LinkedIn ID",
  address:"Address",
  state:"State",
  country:"Country",
  postal:"Postal Code",
  door:"Door No/Building No/Building Name",
  street:"Street Name",
  cityName:"City Name",
  name:"Name of the institute",
  employedinplaceholder:"EmployedIn",
  locationplaceholder:"Work Location",
  stateplaceholder:"State",
  cityplaceholder:"City",
  annualincome:" annual income field is  Required",
  annualtext:"Annual Income",
  date:"Date of Birth",
  religion:"Religion",
  mother:"Mother Tongue",
  link:"Skip & Register Later",

  caste:"caste"

};